/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { IFiltered } from 'interfaces/api-response';
import { IGroupAvatar } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';
import { IMessageEvent, MessageType } from 'interfaces';
import {
  LiveClassTypes, CourseTypes, SessionAudienceTypes, SessionLanguages,
  SessionType, SessionFrequencyTypes,
} from 'config';
import { SessionDocument } from '../../interface';
import {
  CreateSessionState,
  ILiveSessionAvailabilityForm, ILiveClassFrequencyForm,
  ICourseModules, ISessionFeeForm, ICreateSessionForm, ICourseGoals,
  ISessionDetailsForm, IPaginateFilterPayload, ModalState, CourseOnlySessionStatus,
  ISessionCreatedResponse,
} from './interface';

export const initialState: CreateSessionState = {
  stateLoading: false,
  loading: false,
  status: 0,
  entId: '',
  expertId: '',
  sessionId: '',
  isActive: false,
  sessionFee: {
    fee: '0',
    discount: '0',
    currency: 'AUD',
    subscriberFee: '0',
    subscriberCurrency: 'AUD',
    subscriberDiscount: '0',
    observerCurrency: 'AUD',
    observerDiscount: '0',
    observerFee: '0',
    subscribeObserverFee: '0',
    subscribeObserverCurrency: 'AUD',
    subscribeObserverDiscount: '0',
  },
  sessionInformation: {
    sessionType: SessionType.digitalSession,
    userSettings: {
      allowUpload: true,
      allowMessaging: true,
    },
    liveClassType: LiveClassTypes.interactive,
    courseType: CourseTypes.lsm,
    title: '',
    numberOfSessions: 0,
    sessionDuration: 0,
    audienceType: SessionAudienceTypes.public,
    groups: [],
    language: SessionLanguages.english,
    description: '',
    message: '',
    media: [],
    forms: [],
    waitingRoomMedia: {
      fileName: '',
      contentUrl: '',
      fileType: '',
      thumbnailUrl: '',
    },
    isObserverAllowed: false,
    numberOfObservers: 0,
    numberOfParticipants: 2,
    badge: { badgeTitle: '', badgeDescription: '', badgeImgUrl: '' },
    prerequisites: [],
  },
  sessionDetails: {
    highlightMedia: {
      _id: '',
      thumbnail: '',
      title: '',
      fileType: '',
      fileCategory: '',
      description: '',
      contentUrl: '',
    },
    relatedSkills: [],
    sessionOutcomes: [],
    categories: [],
  },
  liveAvailability: {
    availableTimes: [
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Monday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Tuesday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Wednesday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Thursday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Friday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Saturday',

      },
      {
        isAllDay: false,
        isAvailable: false,
        times: [{ from: '', to: '' }],
        day: 'Sunday',

      },
    ],
    sessionMeta: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(14, 'days').format('YYYY-MM-DD'),
      liveClassType: LiveClassTypes.interactive,
    },
  },

  liveClassFrequency: {
    startAt: moment().toISOString(),
    endAt: moment().add(1, 'hours').toISOString(),
    startDate: moment().toISOString(),
    endDate: moment().toISOString(),
    dayOfTheMonth: '1',
    dayOfTheWeek: 'Monday',
    frequency: SessionFrequencyTypes.oneTime,
  },
  courseModules: {
    modules: [],
    isSequentialModuleNavigationEnabled: false,
  },
  courseGoals: {
    goals: [],
  },
  filteredGroups: { result: [], total: 0 },
  subStateLoading: false,
  invitations: [],
  modalState: ModalState.close,
  courseOnlySessionMeta: {
    moduleIndex: 0,
    status: CourseOnlySessionStatus.done,
  },
  bookedSessions: 0,
  bookedObservers: 0,
};

export const createSessionSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/create-session',
  initialState,
  reducers: {
    setExpertId(state:CreateSessionState, action:PayloadAction<string>) {
      state.expertId = action.payload;
    },
    setEntId(state:CreateSessionState, action:PayloadAction<string>) {
      state.entId = action.payload;
    },
    setSessionId(state:CreateSessionState, action:PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    setStatus(state:CreateSessionState, action:PayloadAction<number>) {
      state.status = action.payload;
    },
    setCourseOnlySessionMetaStatus(state:CreateSessionState, action:PayloadAction<string>) {
      state.courseOnlySessionMeta.status = action.payload;
      if (action.payload === CourseOnlySessionStatus.done) {
        window.close();
      }
    },
    setCourseOnlySessionMetaModule(state:CreateSessionState, action:PayloadAction<number>) {
      state.courseOnlySessionMeta.moduleIndex = action.payload;
    },
    setSessionType(state:CreateSessionState, action:PayloadAction<string>) {
      state.sessionInformation.sessionType = action.payload;
    },
    setModalState(state:CreateSessionState, action:PayloadAction<string>) {
      state.modalState = action.payload;
    },
    getSession(state:CreateSessionState) {
      state.stateLoading = true;
    },
    getSessionSucceeded(state:CreateSessionState, action:PayloadAction<SessionDocument>) {
      state.stateLoading = false;

      const session = action.payload;
      state.isActive = session.isActive;
      state.bookedSessions = session?.bookedSessions ?? 0;
      state.bookedObservers = session?.bookedObservers ?? 0;
      state.sessionFee = {
        fee: session.fee || '0.00',
        discount: session.discount || '0',
        currency: session.currency || 'AUD',
        observerCurrency: session.observerCurrency,
        observerDiscount: session.observerDiscount,
        observerFee: session.observerFee,
        subscriberFee: session.subscriberFee ?? '0.00',
        subscriberCurrency: session.subscriberCurrency ?? 'AUD',
        subscriberDiscount: session.subscriberDiscount ?? '0',
        subscribeObserverFee: session.subscribeObserverFee ?? '0.00',
        subscribeObserverCurrency: session.subscribeObserverCurrency ?? 'AUD',
        subscribeObserverDiscount: session.subscribeObserverDiscount ?? '0',
      };
      state.sessionInformation = {
        sessionType: session.sessionType || '',
        userSettings: {
          allowMessaging: session.userSettings?.allowMessaging ?? true,
          allowUpload: session.userSettings?.allowUpload ?? true,
        },
        liveClassType: session?.sessionMeta?.liveClassType || LiveClassTypes.interactive,
        courseType: session.courseType || CourseTypes.lsm,
        title: session.title || '',
        invitedParticipants: session.invitedParticipants || [],
        numberOfSessions: session.numberOfSessions || 0,
        sessionDuration: session.sessionDuration || 0,
        audienceType: session.audienceType || '',
        groups: session.groupsList || [],
        language: session.language || '',
        description: session.description || '',
        message: session.message || '',
        media: session.mediaList || [],
        forms: session.formsList || [],
        waitingRoomMedia: session.waitingRoomMedia || [],
        badge: session.badge || [],
        isObserverAllowed: session.isObserverAllowed || false,
        numberOfObservers: session.numberOfObservers || 0,
        numberOfParticipants: session.numberOfParticipants || 0,
        prerequisites: Array.isArray(session?.prerequisites)
          ? session?.prerequisites.map((i) => ({ ...i, _id: i.id }))
          : [],
      };
      state.sessionDetails = {
        highlightMedia: session?.highlightMedia || initialState.sessionDetails.highlightMedia,
        sessionOutcomes: session?.sessionOutcomes || [],
        relatedSkills: session?.relatedSkills?.map((skill) => (skill.id)) || [],
        categories: session?.categories?.map((i) => i.id),
      };
      state.liveAvailability.availableTimes = session.availableTimes || [];
      state.liveAvailability.sessionMeta = {
        startDate: moment(session?.sessionMeta?.startDate).format('YYYY-MM-DD') || '',
        endDate: moment(session?.sessionMeta?.endDate).format('YYYY-MM-DD') || '',
        liveClassType: session?.sessionMeta?.liveClassType,
      };
      state.liveClassFrequency = {
        startAt: moment(session.frequency?.startAt).format('HH:mm:ss') || '',
        endAt: moment(session.frequency?.endAt).format('HH:mm::ss') || '',
        startDate: moment(session.frequency?.startDate).format('YYYY-MM-DD') || '',
        endDate: moment(session.frequency?.endDate).format('YYYY-MM-DD') || '',
        dayOfTheMonth: session.frequency?.dayOfTheMonth || '',
        dayOfTheWeek: session.frequency?.dayOfTheWeek || '',
        frequency: session.frequency?.frequency || '',
      };
      state.courseModules.modules = session.modules.map((module) => ({
        id: module?.id,
        version: module?.version ?? 0,
        name: module?.name,
        description: module?.description || '',
        badge: module?.badge,
        form: module?.form,
        session: module?.session,
        content: module?.content,
      })) || [];
      // eslint-disable-next-line max-len
      state.courseModules.isSequentialModuleNavigationEnabled = session.isSequentialModuleNavigationEnabled;
      state.courseGoals.goals = session.goals.map((goal) => ({
        title: goal?.title,
      })) || [];
      state.invitations = session.invitations || [];
    },
    getSessionFailed(state:CreateSessionState) {
      state.stateLoading = false;
    },
    createSession(state:CreateSessionState, action:PayloadAction<ISessionFeeForm>) {
      state.loading = true;
      state.sessionFee = action.payload;
    },
    createSessionSucceeded(
      state:CreateSessionState,
      action:PayloadAction<ISessionCreatedResponse>,
    ) {
      state.loading = false;
      state.status = (state.sessionInformation.courseType === CourseTypes.lsm ? 4 : 5);
      if (action.payload.session.audienceType === SessionAudienceTypes.courseOnly) {
        const message:IMessageEvent = {
          type: MessageType.formSubmit,
          data: { ...action.payload.session, moduleIndex: state.courseOnlySessionMeta.moduleIndex },
        };
        window?.opener?.postMessage({ ...message }, window.origin);
        state.sessionId = action.payload.session.id;
      }
    },

    createSessionFailed(state:CreateSessionState) {
      state.loading = false;

      if (state.sessionInformation.audienceType === SessionAudienceTypes.courseOnly) {
        if (state.sessionInformation.sessionType === SessionType.content
          || state.sessionInformation.sessionType === SessionType.digitalSession) {
          state.status = 1;
        } else {
          state.status = (state.sessionInformation.courseType === CourseTypes.lsm ? 2 : 3);
        }
      } else if (state.sessionInformation.sessionType === SessionType.content
          || state.sessionInformation.sessionType === SessionType.digitalSession) {
        state.status = 2;
      } else {
        state.status = (state.sessionInformation.courseType === CourseTypes.lsm ? 3 : 4);
      }
    },
    editSession(state:CreateSessionState, action:PayloadAction<ISessionFeeForm>) {
      state.loading = true;
      state.sessionFee = action.payload;
    },
    editSessionSucceeded(
      state:CreateSessionState,
      action:PayloadAction<SessionDocument>,
    ) {
      state.loading = false;
      state.status = (state.sessionInformation.courseType === CourseTypes.lsm ? 4 : 5);
      if (action.payload.audienceType === SessionAudienceTypes.courseOnly) {
        const message:IMessageEvent = {
          type: MessageType.formSubmit,
          data: { ...action.payload, moduleIndex: state.courseOnlySessionMeta.moduleIndex },
        };
        window?.opener?.postMessage({ ...message }, window.origin);
      }
    },

    AddSessionInformation(
      state:CreateSessionState,
      action:PayloadAction<ICreateSessionForm>,
    ) {
      state.sessionInformation = action.payload;
      state.status = 1;
    },
    AddSessionDetails(
      state:CreateSessionState,
      action:PayloadAction<ISessionDetailsForm>,
    ) {
      state.sessionDetails = action.payload;
      state.status = (state.sessionInformation.audienceType
      === SessionAudienceTypes.courseOnly
       && (state.sessionInformation.sessionType === SessionType.digitalSession
         || state.sessionInformation.sessionType === SessionType.content)) ? 1 : 2;
    },

    AddLiveSessionAvailability(
      state:CreateSessionState,
      action:PayloadAction<ILiveSessionAvailabilityForm>,
    ) {
      state.liveAvailability = action.payload;
      state.status = state.sessionInformation.audienceType
      === SessionAudienceTypes.courseOnly ? 2 : 3;
    },
    AddLiveClassFrequency(
      state:CreateSessionState,
      action:PayloadAction<ILiveClassFrequencyForm>,
    ) {
      state.liveClassFrequency = action.payload;
      state.status = state.sessionInformation.audienceType
      === SessionAudienceTypes.courseOnly ? 2 : 3;
    },
    AddCourseGoals(
      state:CreateSessionState,
      action:PayloadAction<ICourseGoals>,
    ) {
      state.courseGoals = action.payload;
      state.status = 3;
    },
    AddCourseModules(
      state:CreateSessionState,
      action:PayloadAction<ICourseModules>,
    ) {
      state.courseModules = action.payload;
      state.status = (state.sessionInformation.courseType === CourseTypes.lsm ? 3 : 4);
    },
    updateActive(state:CreateSessionState, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    getFilteredGroups(state: CreateSessionState, _action:PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredGroupsSucceeded(
      state: CreateSessionState,
      action: PayloadAction<IFiltered<IGroupAvatar>>,
    ) {
      state.filteredGroups = action.payload;
      state.subStateLoading = false;
    },
    getFilteredGroupsFailed(state: CreateSessionState) {
      state.subStateLoading = false;
    },
    resendInvite(state:CreateSessionState, _action:PayloadAction<string>) {
      state.subStateLoading = true;
    },
    resendInviteSucceeded(state:CreateSessionState) {
      state.subStateLoading = false;
      state.modalState = ModalState.close;
    },
    resendInviteFailed(state:CreateSessionState) {
      state.subStateLoading = false;
    },
    resetCourseOnlySessionMeta(state:CreateSessionState) {
      state.courseOnlySessionMeta = initialState.courseOnlySessionMeta;
    },
    reset(state:CreateSessionState) {
      state.status = initialState.status;
      state.loading = false;
      state.isActive = initialState.isActive;
      state.sessionInformation = initialState.sessionInformation;
      state.sessionDetails = initialState.sessionDetails;
      state.sessionFee = initialState.sessionFee;
      state.liveAvailability = initialState.liveAvailability;
      state.liveClassFrequency = initialState.liveClassFrequency;
      state.courseModules = initialState.courseModules;
      state.courseGoals = initialState.courseGoals;
      state.invitations = initialState.invitations;
    },

  },
});

export const { actions: createSessionActions } = createSessionSlice;
