/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IOnboardGeneralInformationModalForm,
  IOnboardSitePreferencesModalForm,
  IOnboardContentModalForm,
  IOnboardChargesModalForm,
  OnboardEntAccState,
  IOnboardAdminModalForm,
  ModalStates,
} from './interface';
import { ICreateAdminModalForm } from '../edit-account/components/admins/components/create-admin/interface';
import { AdminDocument } from '../edit-account/components/admins/interface';
import { ExpertDocument } from '../edit-account/components/experts/interface';
import { EntAccountDocument } from '../../interface';

export const initialState: OnboardEntAccState = {
  id: '',
  active: true,
  stateLoading: false,
  stateAdminLoading: false,
  stateExpertLoading: false,
  loading: false,
  status: 0,
  modal: '',
  generalInformationForm: {
    name: '',
    entUrl: '',
    email: '',
    currency: 'AUD',
    country: '',
    city: '',
    facebookUrl: '',
    instagramUrl: '',
    linkedinUrl: '',
    youtubeUrl: '',
    twitterUrl: '',
    entLogoUrl: '',
    webLogoUrl: '',
    categories: [
      {
        name: '',
      },
    ],
    skills: [
      {
        name: '',
      },
    ],
  },
  sitePreferencesForm: {
    colors: {
      primaryColor: '',
      secondaryColor: '',
    },
    headerTemplate: '',
    isFontDefault: '',
    headerFont: { name: '', url: '' },
    bodyFont: { name: '', url: '' },
    sponsorContent: [],
    promotionalContent: { url: '', fileCategory: '' },
  },
  contentForm: {
    landingPageSections: {
      groups: false,
      sessions: false,
      featured: false,
      experts: false,
      articles: false,
      testimonials: false,
    },
    landingPageContent: {
      tagLine: '',
      title: '',
      body: '',
    },
    groupLandingPageContent: {
      title: '',
      body: '',
    },
    expertLandingPageContent: {
      title: '',
      body: '',
    },
    sessionLandingPageContent: {
      title: '',
      body: '',
    },
    articleLandingPageContent: {
      title: '',
      body: '',
    },
    contentLandingPageContent: {
      title: '',
      body: '',
    },
  },
  chargesForm: {
    charges: {
      adminFee: 0,
      forExpert: 0,
      forEntClient: 0,
    },
    bankDetails: {
      accountName: '',
      accountNumber: '',
      bankName: '',
      branchName: '',
    },
  },
  adminForm: {
    admins: [],
  },
  expertForm: {
    experts: [
    ],
  },
  isOnBoardingDone: false,
  entUrl: '',
  stripeAccountCompleted: false,
  stripeConnectUrl: '',
};

export const OnboardEntAccSlice = createSlice({
  name: 'feature/ent-acc/onboard-ent-acc',
  initialState,
  reducers: {
    setId(state: OnboardEntAccState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setStatus(state:OnboardEntAccState, action:PayloadAction<number>) {
      state.status = action.payload;
    },
    getEntAcc(state: OnboardEntAccState) {
      state.stateLoading = true;
    },
    getEntAccSucceeded(state: OnboardEntAccState, action: PayloadAction<EntAccountDocument>) {
      state.active = action.payload.active || false;
      state.sitePreferencesForm.headerTemplate = action.payload.headerTemplate || '';
      state.sitePreferencesForm.promotionalContent = action.payload.promotionalContent?.[0] || { url: '', contentUrl: '', fileCategory: '' };
      state.sitePreferencesForm.sponsorContent = action.payload.sponsorContent || null;
      state.generalInformationForm.categories = action.payload.categories || null;
      state.generalInformationForm.skills = action.payload.skills || null;
      state.sitePreferencesForm.colors = action.payload.colors || '';
      state.sitePreferencesForm.bodyFont = action.payload.bodyFont || '';
      state.sitePreferencesForm.headerFont = action.payload.headerFont || '';
      state.sitePreferencesForm.isFontDefault = action.payload.isFontDefault || 'true';
      state.generalInformationForm.email = action.payload.email || '';
      state.generalInformationForm.name = action.payload.name || '';
      state.generalInformationForm.entUrl = action.payload.entUrl || '';
      state.generalInformationForm.currency = action.payload.currency || 'AUD';
      state.generalInformationForm.city = action.payload.city || '';
      state.generalInformationForm.country = action.payload.country || '';
      state.generalInformationForm.facebookUrl = action.payload.facebookUrl || '';
      state.generalInformationForm.twitterUrl = action.payload.twitterUrl || '';
      state.generalInformationForm.instagramUrl = action.payload.instagramUrl || '';
      state.generalInformationForm.youtubeUrl = action.payload.youtubeUrl || '';
      state.generalInformationForm.linkedinUrl = action.payload.linkedinUrl || '';
      state.generalInformationForm.webLogoUrl = action.payload.webLogoUrl || '';
      state.generalInformationForm.entLogoUrl = action.payload.entLogoUrl || '';
      state.contentForm.landingPageSections = action.payload.landingPageSections || {
        groups: false,
        sessions: false,
        featured: false,
        experts: false,
        articles: false,
        testimonials: false,
      };
      state.contentForm.landingPageContent = action.payload.landingPageContent
      || initialState.contentForm.landingPageContent;
      state.contentForm.articleLandingPageContent = action.payload.articleLandingPageContent
      || initialState.contentForm.articleLandingPageContent;
      state.contentForm.expertLandingPageContent = action.payload.expertLandingPageContent
      || initialState.contentForm.expertLandingPageContent;
      state.contentForm.sessionLandingPageContent = action.payload.sessionLandingPageContent
       || initialState.contentForm.sessionLandingPageContent;
      state.contentForm.contentLandingPageContent = action.payload.contentLandingPageContent
      || initialState.contentForm.contentLandingPageContent;
      state.contentForm.groupLandingPageContent = action.payload.groupLandingPageContent
      || initialState.contentForm.groupLandingPageContent;
      state.chargesForm.bankDetails = action.payload.bankDetails || '';
      state.chargesForm.charges = action.payload.charges || 0;
      state.isOnBoardingDone = action.payload.isOnBoardingDone;
      state.stripeAccountCompleted = action.payload.stripeAccountCompleted || false;
      state.entUrl = action.payload.entUrl;
      state.loading = false;
      state.stateLoading = false;
    },
    getEntAccFailed(state: OnboardEntAccState) {
      state.stateLoading = false;
    },
    getAdminByEnt(state: OnboardEntAccState) {
      state.loading = false;
      state.stateAdminLoading = true;
    },
    getAdminByEntSucceded(state: OnboardEntAccState, action: PayloadAction<AdminDocument[]>) {
      state.adminForm.admins = action.payload;
      state.loading = false;
      state.stateAdminLoading = false;
    },
    getAdminByEntFailed(state: OnboardEntAccState) {
      state.loading = true;
      state.stateAdminLoading = false;
    },
    getExpertByEnt(state: OnboardEntAccState) {
      state.stateExpertLoading = true;
    },
    getExpertByEntSucceded(state: OnboardEntAccState, action: PayloadAction<ExpertDocument[]>) {
      state.expertForm.experts = action.payload;
      state.loading = false;
      state.stateExpertLoading = false;
      state.modal = ModalStates.close;
    },
    getExpertByEntFailed(state: OnboardEntAccState) {
      state.loading = true;
      state.stateExpertLoading = false;
    },
    getStripConnectUrl(state: OnboardEntAccState) {
      state.loading = true;
    },
    getStripConnectUrlSucceeded(state: OnboardEntAccState, action: PayloadAction<string>) {
      state.stripeConnectUrl = action.payload;
      state.loading = false;
    },
    getStripConnectUrlFailed(state: OnboardEntAccState) {
      state.loading = false;
    },
    AddGeneralInformation(
      state: OnboardEntAccState,
      action: PayloadAction<IOnboardGeneralInformationModalForm>,
    ) {
      state.generalInformationForm = action.payload;
      state.status = 1;
    },

    AddAdmin(state: OnboardEntAccState, action: PayloadAction<AdminDocument>) {
      state.adminForm.admins = [...state.adminForm.admins, { ...action.payload }];
    },
    AddExpert(state: OnboardEntAccState, action: PayloadAction<ExpertDocument>) {
      state.expertForm.experts = [...state.expertForm.experts, { ...action.payload }];
    },
    AddSitePreferences(
      state: OnboardEntAccState,
      action: PayloadAction<IOnboardSitePreferencesModalForm>,
    ) {
      state.sitePreferencesForm = action.payload;
      state.status = 2;
    },
    AddContent(state: OnboardEntAccState, action: PayloadAction<IOnboardContentModalForm>) {
      state.contentForm = action.payload;
      state.status = 3;
    },
    AddAdmins(state: OnboardEntAccState, action: PayloadAction<IOnboardAdminModalForm>) {
      state.adminForm = action.payload;
      state.status = 5;
    },
    openModal(state: OnboardEntAccState, action: PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: OnboardEntAccState) {
      state.modal = ModalStates.close;
    },
    createAdmins: (
      state: OnboardEntAccState,
      action: PayloadAction<
      { firstname: string; lastname: string; email: string; authority: string }[]
      >,
    ) => { },
    createAdmin(
      state: OnboardEntAccState,
      action: PayloadAction<ICreateAdminModalForm>,
    ) { state.loading = true; },
    createAdminFailed(
      state: OnboardEntAccState,
    ) { state.loading = false; },
    deleteAdmin: (state: OnboardEntAccState, action: PayloadAction<AdminDocument>) => { },
    changeAdminAuthorityLevel: (
      state: OnboardEntAccState,
      action: PayloadAction<{ id: string; authorityLevel: string }>,
    ) => {
      state.adminForm.admins = state.adminForm.admins.map((admin) => {
        if (admin.id === action.payload.id) {
          admin.attributes.authorityLevel = action.payload.authorityLevel;
        }

        return admin;
      });
    },
    inviteExpert: (
      state: OnboardEntAccState,
      action: PayloadAction<{
        email: string;
        isActive: boolean;
      }>,
    ) => { state.loading = true; },
    inviteExperts: (
      state: OnboardEntAccState,
      action: PayloadAction<
      {
        email: string;
        isActive: boolean;
      }[]
      >,
    ) => { state.loading = true; },
    inviteExpertFailed: (state: OnboardEntAccState) => { state.loading = false; },
    deleteExpert: (state: OnboardEntAccState, action: PayloadAction<ExpertDocument>) => {
      state.loading = true;
    },
    deleteExpertFailed: (state: OnboardEntAccState) => { state.loading = false; },

    editGeneralInformationSucceeded(state: OnboardEntAccState) {
      state.status = 1;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editGeneralInformationFailed(state: OnboardEntAccState) {
      state.loading = false;
      state.status = 0;
    },
    editGeneralInformation(
      state: OnboardEntAccState,
      action: PayloadAction<IOnboardGeneralInformationModalForm>,
    ) {
      state.loading = true;
      state.generalInformationForm = action.payload;
    },
    editSitePreferencesSucceeded(state: OnboardEntAccState) {
      state.status = 2;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editSitePreferencesFailed(state: OnboardEntAccState) {
      state.loading = false;
      state.status = 1;
    },
    editSitePreferences(
      state: OnboardEntAccState,
      action: PayloadAction<IOnboardSitePreferencesModalForm>,
    ) {
      state.loading = true;
      state.sitePreferencesForm = action.payload;
    },
    editContentsSucceeded(state: OnboardEntAccState) {
      state.status = 3;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editContentsFailed(state: OnboardEntAccState) {
      state.loading = false;
      state.status = 2;
    },
    editContents(state: OnboardEntAccState, action: PayloadAction<IOnboardContentModalForm>) {
      state.loading = true;
      state.contentForm = action.payload;
    },
    editChargesSucceeded(state: OnboardEntAccState) {
      state.status = 4;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editChargesFailed(state: OnboardEntAccState) {
      state.loading = false;
      state.status = 3;
    },
    editCharges(state: OnboardEntAccState, action: PayloadAction<IOnboardChargesModalForm>) {
      state.loading = true;
      state.chargesForm = action.payload;
    },
    isOnboardingSucceeded(state: OnboardEntAccState) {
      state.loading = false;
      state.modal = ModalStates.close;
      window.location.pathname = '/';
    },
    isOnboardingFailed(state: OnboardEntAccState) {
      state.loading = false;
    },
    isOnboarding(state: OnboardEntAccState) {
      state.loading = true;
    },
    reset: () => initialState,
  },
});
export const { actions: onboardEntAccActions } = OnboardEntAccSlice;
