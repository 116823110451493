/* eslint-disable max-len */
import React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  PrimaryTextField,
  PrimarySelect,
  ErrorMessage,
  PrimaryLoadingButton,
  PrimaryAutoComplete,
} from 'components';
import '../../index.scss';
import {
  selectSCAdminsLoader,
  selectSCAdmins,
} from 'pages/dashboard/components/ent-account/components/ent-accounts-view/selector';
import { viewEntAccActions } from 'pages/dashboard/components/ent-account/components/ent-accounts-view/slice';
import { IOption } from 'interfaces';
import { getCitiesForCountry, getCountries, getOptionsFromEnum } from 'utils/data-utils';
import { ENVIRONMENT, CurrencyTypes } from 'config';
import { ICreateGeneralInformationForm } from '../../../../interface';

interface Props {
  onSubmit: SubmitHandler<any>;
}

const GeneralInformationForm = (props: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch, getValues,
  } = useFormContext<ICreateGeneralInformationForm>();
  const { onSubmit } = props;
  const dispatch = useDispatch();
  const [scAdminOptions, setScAdminOptions] = React.useState([{ id: '', value: '', text: '' }]);
  const scAdmins = useSelector(selectSCAdmins);
  const scAdminsLoading = useSelector(selectSCAdminsLoader);
  const currencyOptions: IOption[] = getOptionsFromEnum(CurrencyTypes);

  const [cities, setCities] = React.useState<IOption[]>([{ id: 1, value: '', text: '' }]);

  const watchName = watch('name');

  const countries: IOption[] = getCountries().map((country, id) => ({
    country,
    id,
    value: country,
    text: country,
  }));

  const currentCountry:IOption = countries.filter((country) => (country.value === getValues('country')))[0];
  const [selectedCountry, setSelectedCountry] = React.useState<IOption>({
    id: currentCountry.id,
    value: currentCountry.value,
    text: currentCountry.text,
  });
  const cityId = getCitiesForCountry(getValues('country')).indexOf(getValues('city'));
  const [selectedCity, setSelectedCity] = React.useState<IOption>({ id: cityId, value: getValues('city'), text: getValues('city') });

  React.useEffect(() => {
    dispatch(viewEntAccActions.getSCAdmins());
  }, []);

  React.useEffect(() => {
    setScAdminOptions([
      ...scAdmins.map((scAdmin) => ({
        id: scAdmin.id,
        value: scAdmin.id,
        text: `${scAdmin.firstName} ${scAdmin.lastName}`,
      })),
    ]);
  }, [scAdmins]);

  React.useEffect(() => {
    if (selectedCountry) {
      setCities(
        getCitiesForCountry(selectedCountry.value as string).map((city, id) => ({
          id,
          value: city,
          text: city,
        })),
      );
    }
  }, [selectedCountry]);

  React.useEffect(() => {
    const url = `${watchName.toLowerCase().replaceAll(/([^A-Za-z0-9 ]|\s)/g, '')}`;
    setValue('entUrl', url);
  }, [watchName]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item"> General information</h3>

      <div className="form-item">
        <Controller
          control={control}
          name="name"
          render={({ field }) => <PrimaryTextField {...field} label="Name of enterprise" />}
        />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="entUrl"
          render={({ field }) => (
            <PrimaryTextField disabled testId="create-ent-url" value={field.value + ENVIRONMENT.USER_HUB_DOMAIN} label="Enterprise URL" />
          )}
        />

        <ErrorMessage>{errors?.entUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="email"
          render={({ field }) => <PrimaryTextField {...field} label="Email address" />}
        />
        <ErrorMessage>{errors?.email?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="currency"
          render={({ field }) => (
            <PrimarySelect {...field} label="Currency" options={currencyOptions} placeholder="Select Currency" />
          )}
        />
        <ErrorMessage>{errors?.currency?.message}</ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs className="form-item">
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange } }) => (
              <PrimaryAutoComplete
                value={selectedCountry}
                label="Country"
                placeholder="Select country"
                options={countries}
                setValue={setSelectedCountry}
                onChange={(value: string) => {
                  onChange(value);
                  setSelectedCity({ id: '', value: '', text: '' });
                }}
              />
            )}
          />
          <ErrorMessage>{errors?.country?.message}</ErrorMessage>
        </Grid>
        <Grid item xs className="form-item">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange } }) => (
              <PrimaryAutoComplete
                value={selectedCity}
                label="City"
                placeholder="Select city"
                options={cities}
                setValue={setSelectedCity}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage>{errors?.city?.message}</ErrorMessage>
        </Grid>
      </Grid>

      <div className="form-item">
        <Controller
          control={control}
          name="slocoachAdmin"
          render={({ field }) => (
            <PrimarySelect
              {...field}
              label="Slocoach admin"
              options={scAdminOptions}
              loading={scAdminsLoading}
              placeholder="Select admin"
            />
          )}
        />

        <ErrorMessage>{errors?.slocoachAdmin?.message}</ErrorMessage>
      </div>

      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};

export default GeneralInformationForm;
