/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';

export const EmailValidation = () => (
  yup.string().trim().required('Email address is a required field').test('is-valid', (message) => `${message.value} is an invalid email`, (value) => (value ? isEmail(value) : new yup.ValidationError('Invalid value')))
);
// password validation for 8 characters, 1 uppercase, 1 lowercase, 1 number
// and allows special characters
export const PasswordValidation = () => yup
  .string()
  .trim()
  .required('Password is a required field')
  .max(50)
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-=_+[\]{}|;:",.<>/?`~\\]{8,}$/, 'Your password does not meet our password rules. Use at least 8 characters, 1 uppercase, 1 lowercase, and 1 number');

export const urlRegMatch = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const charOnlyRegMatch = /^[aA-zZ,\s]+$/;
export const charAndNumRegMatch = /^[aA-zZ,0-9\s]+$/;

export const UrlValidation = () => yup.string().trim().matches(urlRegMatch, 'Invalid URL');

export const ContentImageDataValidation = yup.object().shape({
  url: yup.string().trim().required('Image is a required field'),
  contentUrl: yup.string().trim().url('invalid url'),
});
