const API = {
  POST_ENT_ACC: {
    path: '/ent-account/update',
    method: 'POST',
  },
  GET_ENT_ACC: {
    path: '/ent-account/details',
    method: 'GET',
  },
  GET_ADMIN_BY_ENT: {
    path: '/ent-admin/details/ent',
    method: 'GET',
  },
  DELETE_ADMIN: {
    path: '/ent-admin/delete',
    method: 'DELETE',
  },
  POST_STRIPE_CONNECT_URL: {
    path: '/stripe/connect/generate-url',
    method: 'POST',
  },
  POST_CREATE_ADMIN: {
    path: '/ent-admin/create',
    method: 'POST',
  },
  POST_UPDATE_ADMIN: {
    path: '/ent-admin/update',
    method: 'POST',
  },
  POST_CREATE_EXPERT: {
    path: '/expert/create',
    method: 'POST',
  },
  POST_INVITE_EXPERT: {
    path: '/expert/invite',
    method: 'POST',
  },
  GET_EXPERTS_BY_ENT: {
    path: '/expert/details/ent',
    method: 'GET',
  },
  POST_UPDATE_EXPERT: {
    path: '/expert/update',
    method: 'POST',
  },
  DELETE_EXPERT: {
    path: '/expert/delete',
    method: 'DELETE',
  },
  GET_ARTICLE_BY_ENT: {
    path: '/article/details/ent',
    method: 'GET',
  },
  POST_CREATE_ARTICLE: {
    path: '/article/create',
    method: 'POST',
  },
  DELETE_ARTICLE: {
    path: '/article/delete',
    method: 'DELETE',
  },
  POST_CATEGORIES: {
    path: '/categories?allowBulk=true',
    method: 'POST',
  },
};
export default API;
